export const DEFAULT_ERROR_TEXT = `An error occurred, please try again later`;

export const AUTHORIZATION_KEY = 'barbr';
export const USER_UUID = 'user-uuid';

export const SHOP_SERVICE_TYPE = 'shop';
export const MOBILE_SERVICE_TYPE = 'mobile';

export const CASH_PAYMENT_METHOD = 'cash';
export const CARD_PAYMENT_METHOD = 'online';
export const APPLE_PAYMENT_METHOD = 'apple_pay';
export const BOTH_PAYMENT_METHOD = 'cash_and_online';

export const UPCOMING_BOOKING_FILTER = 'upcoming';

export const SERVICES_TYPE_CHOICES = [
  {
    label: 'Shop',
    value: SHOP_SERVICE_TYPE,
  },
  {
    label: 'Callouts',
    value: MOBILE_SERVICE_TYPE,
  },
];

export const PAYMENT_METHOD_CHOICES = [
  {
    label: 'Card',
    value: CARD_PAYMENT_METHOD,
  },
  {
    label: 'Cash',
    value: CASH_PAYMENT_METHOD,
  },
];

export const MY_BOOKING_FILTER_CHOICES = [
  {
    label: 'Upcoming',
    value: UPCOMING_BOOKING_FILTER,
  },
  {
    label: 'Past',
    value: 'past',
  },
  {
    label: 'Waiting List',
    value: 'waiting-list',
  },
];

export const HELP_EMAIL = 'support@barbr.io';
export const FAQ_URL = 'https://getbarbr.com/faqs';
export const BARBER_LP_URL = 'https://getbarbr.com';
export const TERMS_URL = 'https://getbarbr.com/terms';
export const LANDING_PAGE_URL = 'https://getbarbr.com';
export const BLOG_URL = 'https://www.getbarbr.com/blog';
export const PRIVACY_URL = 'https://getbarbr.com/privacy';
export const TIKTOK_URL = 'https://www.tiktok.com/@getbarbr';
export const INSTAGRAM_URL = 'https://www.instagram.com/getbarbr/';
export const WP_SUPPORT_URL = 'https://wa.me/message/WELA5ZKG4XPKG1';
export const IOS_APP_URL = `https://apps.apple.com/gb/app/barbr-biz/id1550249560`;
